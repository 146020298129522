const solutionsArchitectureDetails = [
  {
    id: "architecture-consulting",
    title: "Architecture Consulting",
    text: "Uncertain about the software architecture that would suit your needs? Our skilled experts can help you find answers to this and other questions. They will assist you in comprehending the present condition of your product and recommend an efficient strategy to enhance it. In addition, our organization extends guidance and aid to businesses in designing or refining their software architecture, including aiding in creating software systems and bettering current products.",
    component: "serviceBlock",
  },
  {
    id: "architecture-assessment",
    title: "Architecture Assessment",
    text: "We will help you make your product high quality through a professional inspection of software architecture that will help you find vulnerable places in your product architecture and update it. An architecture assessment involves our company evaluating the architecture of an existing software system to identify areas for improvement or optimization. This may include testing the software system's components, interactions, and interfaces, as well as evaluating its scalability, performance, security, and maintainability.",
    component: "serviceBlock",
  },
  {
    id: "architecture-migration",
    title: "Architecture Migration",
    text: "Improve and change your architecture with the times and rapid changes with Sophotech - an enterprise architecture consulting company. We work in the field of architecture transformation, which involves making significant changes to the architecture of an existing software system to increase its efficiency, effectiveness, and long-term sustainability. It may include refactoring software system code, implementing new technologies or design patterns, or redesigning software system components, interactions, or interfaces.",
    component: "serviceBlock",
  },
  {
    id: "architecture-design",
    title: "Architecture Design",
    text: "Do you need help scaling your new product? Our activity aims to attract professional specialists to create quality products using our consulting services. In this area, we develop the high-level design and structure of a new software system or function. It includes identifying the software system's components, interactions, and interfaces and determining its scalability, performance, security, and maintenance requirements.",
    component: "serviceBlock",
  },
  {
    id: "designing-software-systems",
    title: "Designing software systems",
    text: "Our company helps organizations design software systems that meet their specific needs, taking into account factors such as scalability, performance, security, and maintainability. With Sophotech, you can effectively design, build, optimize, and evaluate various digital solutions. Our services encompass a comprehensive analysis of the client's business requests in order to design a bespoke software system that meets its specific needs. We also consider various factors such as scalability, user experience, security, and seamless integration with existing systems. Additionally, we provide consistent support and maintenance services to ensure the software system is up-to-date and functioning optimally.",
    component: "serviceBlock",
  },
  {
    id: "improving-existing-software-systems",
    title: "Improving existing software systems",
    text: null,
    component: "customServiceBlock",
  },
  {
    id: "evaluating-software-options",
    title: "Evaluating software options",
    text: "Sophotech helps organizations evaluate different software options, such as off-the-shelf software packages or custom software development, to determine which approach is best suited to their needs. As an IT company that provides software evaluation services, we begin by conducting a detailed analysis of our client's business needs and requirements to identify appropriate software options.We consider factors such as features, functionality, and scalability of each software option and aspects like cost, compatibility, and vendor support. Our evaluation process is thorough and methodical, ensuring that we examine all relevant factors and provide accurate recommendations. We then present our findings and recommendations to the client in a comprehensive report or presentation, which can help them decide which software option to choose.",
    component: "serviceBlock",
  },
];

export default solutionsArchitectureDetails;
