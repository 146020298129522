import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext();

const AllContext = ({ children }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [stickyMenu, setStickyMenu] = useState(false);

  useEffect(() => {
    const stickyMenuBar = () => {
      if (window.scrollY > 80) {
        setStickyMenu(true);
      } else {
        setStickyMenu(false);
      }
    };

    window.addEventListener("scroll", stickyMenuBar);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", stickyMenuBar);
    };
  }, []);

  const values = {
    isVideoOpen,
    setIsVideoOpen,
    stickyMenu,
  };
  return (
    <>
      <AppContext.Provider value={values}>{children}</AppContext.Provider>
    </>
  );
};

AllContext.propTypes = {
  children: PropTypes.object,
};

export default AllContext;
