import React from "react";
import ContactForm from "../../../components/ContactForm/ContactForm";
const HomeContact = () => {
  return (
    <>
      <section className="contact__area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xl-12">
              <div className="contact__map">
                <div className="contact__wrapper d-md-flex justify-content-between">
                  <div className="contact__form">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeContact;
