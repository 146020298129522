import React from "react";
import PropTypes from "prop-types";

const ServiceBlock = ({ elementId, title, text }) => {
  return (
    <>
      <div id={elementId}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </>
  );
};

ServiceBlock.propTypes = {
  elementId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ServiceBlock;
