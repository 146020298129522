import React from "react";
import HomeOneHeader from "./HomeOneHeader/HomeOneHeader";
import HomeHeroArea from "./HomeHeroArea/HomeHeroArea";
import HomeFeatures from "./HomeFeatures/HomeFeatures";
import HomeAbout from "./HomeAbout/HomeAbout";
import HomeCapabilities from "./HomeCapabilities/HomeCapabilities";
import HomeServices from "./HomeServices/HomeServices";
import HomeContact from "./HomeContact/HomeContact";
import Footer from "../../components/shared/Footer";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import PageHelmet from "../../components/shared/PageHelmet";

const Home = () => {
  return (
    <>
      <PageHelmet pageTitle="Sophotech" />
      <HomeOneHeader />
      <HomeHeroArea />
      <HomeFeatures />
      <HomeAbout />
      <HomeCapabilities />
      <HomeServices />
      <CommonCtaArea />
      <HomeContact />
      <Footer />
    </>
  );
};

export default Home;
