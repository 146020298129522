import React from "react";

const HomeTwoFaq = () => {
  return (
    <>
      <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
        <div
          className="faq__thumb"
          style={{
            background: `url(assets/img/faq/faq-1.jpg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
              <div className="faq__wrapper">
                <div
                  className="section__title section__title-3 mb-25 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <span>Our goals</span>
                  <h2>
                    Knowledge is <br />
                    the best investment
                  </h2>
                </div>

                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-controls="collapseOne"
                        >
                          Expertise leads to better outcomes
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        Investing in knowledge helps companies stay ahead of the
                        latest advancements in network infrastructure, cloud
                        solutions, cybersecurity, and technical support.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Continuous learning drives innovation
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        An experienced team provides better and more efficient
                        solutions, leading to increased productivity and cost
                        savings for clients.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Knowledge enhances competitiveness
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        Investing in knowledge enhances the quality of service
                        offered, resulting in higher customer satisfaction and
                        longer-term business relationships.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFaq;
