import PropTypes from "prop-types";
import React from "react";

const CustomServiceBlock = ({ elementId, title }) => {
  return (
    <>
      <div id={elementId}>
        <h3>{title}</h3>
        <p>
          We give the opportunity to organizations to improve the architecture
          of their existing software systems by identifying areas for
          optimization, refactoring, or redesign. As an IT company specializing
          in improving existing software systems, your services may involve a
          variety of tasks and activities.
        </p>
        <p>These can include analyzing the software system:</p>
        <ol>
          <p>
            <li>1. Identifying areas for improvement.</li>
            <li>
              2. Refactoring the codebase, upgrading the software to the latest
              version.
            </li>
            <li>3. Fixing bugs and errors.</li>
            <li>4. Optimizing the user experience.</li>
            <li>5. Tuning the performance and endurance of the system.</li>
          </p>
        </ol>
        <p>
          Ultimately, our goal is to enhance the functionality, performance, and
          of course, the usability of the software system, resulting in a more
          effective and efficient solution.
        </p>
      </div>
    </>
  );
};

CustomServiceBlock.propTypes = {
  elementId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default CustomServiceBlock;
