import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import React from "react";
import urlsData from "../../../data/urlsData";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area pb-200 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                <img src="assets/img/about/about-1.png" alt="About us" />
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <span>Who we are</span>
                  <h2>Cloud Consultancy and Dedicated Teams</h2>
                </div>
                <p>
                  We are a team of experts specializing in creating innovative
                  and effective software solutions for our clients.
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        Cloud Native Development Services
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        DevOps Consulting and Dedicated Teams
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          <FaCheck />
                        </i>
                        Machine Learning & Artificial Intelligence Solutions
                      </span>
                    </li>
                  </ul>
                </div>
                <Link to={urlsData.services} className="z-btn ">
                  What we do
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
