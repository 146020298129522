const urlsData = {
  root: "/",
  home: "/home",
  about: "/about",
  careers: "/careers",
  contact: "/contact",
  services: "/services",
  service_1: "/cloud-solutions-architecture",
  service_2: "/devops-consulting",
  service_3: "/software-development",
  service_4: "/production-readiness",
};

export default urlsData;
