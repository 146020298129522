const ProductPageContent = [
    {
        'id': 'prototype-refinement-and-production-readiness-services',
        'title': 'Prototype Refinement and Production-Readiness Services',
        'text': 'Our experts transform your prototype into a polished, production-ready application. We undertake a thorough analysis of the prototype, identifying any potential bottlenecks and enhancement opportunities. We then implement changes that augment product functionality, reliability, and performance, preparing your software for a seamless transition to production.',
        'component': 'serviceBlock'
    },
    {
        'id': 'performance-optimization-for-production-deployment',
        'title': 'Performance Optimization for Production Deployment',
        'text': 'We employ advanced techniques and industry best practices to optimize the performance of your software for production deployment. Our focus extends from fine-tuning the code and database structure to streamlining the application architecture, ensuring your software operates at its peak performance level when it reaches your users.',
        'component': 'serviceBlock'
    },
    {
        'id': 'software-scalability-and-efficiency-solutions',
        'title': 'Software Scalability and Efficiency Solutions',
        'text': 'Scaling your software solution to meet growing user demands is a critical aspect of product development. Our experienced team devises and implements strategic scalability solutions that accommodate growth, ensuring your product remains efficient and reliable even as your user base expands.',
        'component': 'serviceBlock'
    },
    {
        'id': 'fast-track-product-launch-strategy',
        'title': 'Fast-Track Product Launch Strategy',
        'text': 'We understand that time-to-market is a critical success factor. Therefore, we offer fast-track product launch strategies, allowing you to bring your product to market rapidly without compromising on quality. Our methodical approach ensures a smooth transition from prototype to production, avoiding costly hiccups and maximizing your product\'s success potential.',
        'component': 'serviceBlock'
    },
    {
        'id': 'end-to-end-code-review-and-software-testing-services',
        'title': 'End-to-End Code Review and Software Testing Services',
        'text': 'Ensuring the integrity and reliability of your software is paramount to us. We provide comprehensive code review and testing services, scrutinizing every line of code and running exhaustive test suites to detect and rectify any bugs or vulnerabilities. Our rigorous testing methodologies give you the confidence of a flawless, robust software product.',
        'component': 'serviceBlock'
    },
    {
        'id': 'robust-devops-and-release-management-solutions',
        'title': 'Robust DevOps and Release Management Solutions',
        'text': 'Effective DevOps and release management are crucial for a smooth product launch. We offer robust DevOps solutions that align your development and operations for improved collaboration and efficiency. Our release management practices ensure seamless deployments and updates, enabling you to deliver continuous value to your customers with minimized disruption.',
        'component': 'serviceBlock'
    }
];
export default ProductPageContent;
