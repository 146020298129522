import React from "react";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import ServicesDetailsHeader from "./ServicesDetailsHeader/ServicesDetailsHeader";
import ProductDetailsArea from "./ServicesDetailsArea/ProductDetailsArea";

const ProductDetails = () => {
  return (
    <>
      <PageHelmet pageTitle="DevOps and Infrastructure Services" />
      <HomeOneHeader />
      <ServicesDetailsHeader />
      <ProductDetailsArea />
      <Footer />
    </>
  );
};

export default ProductDetails;
