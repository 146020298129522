/* eslint-disable no-undef */
import React from "react";

function ContactForm() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "a583d3b6-ca0e-42ac-878b-654d2b8c01a6");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    setResult(res.message);
  };

  return (
    <div className="ContactForm contact__form">
      <h1>Get in Touch</h1>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          required={true}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          required={true}
        />
        <textarea
          name="message"
          placeholder="Your Message"
          required={true}
        ></textarea>
        <input
          className="form-control"
          type="file"
          name="file"
          accept=".pdf"
        />
        <button type="submit" className="z-btn">
          Send Message
        </button>
      </form>
      <span>{result}</span>
    </div>
  );
}

export default ContactForm;
