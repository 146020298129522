import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import SolArchitectureDetails from "./pages/ServicesDetails/SolArchitectureDetails";
import urlsData from "./data/urlsData";
import DevOpsDetails from "./pages/ServicesDetails/DevOpsDetails";
import SoftwareDetails from "./pages/ServicesDetails/SoftwareDetails";
import ProductDetails from "./pages/ServicesDetails/ProductDetails";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={urlsData.root} element={<Home />} />
          <Route path={urlsData.home} element={<Home />} />
          <Route path={urlsData.about} element={<About />} />
          <Route path={urlsData.services} element={<Services />} />
          <Route path={urlsData.service_1} element={<SolArchitectureDetails />} />
          <Route path={urlsData.service_2} element={<DevOpsDetails />} />
          <Route path={urlsData.service_3} element={<SoftwareDetails />} />
          <Route path={urlsData.service_4} element={<ProductDetails />} />
          <Route path={urlsData.contact} element={<Contact />} />
          <Route
            path={urlsData.careers}
            component={() => {
              window.location.href = "https://sopho.zohorecruit.com/";
              return null;
            }}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
