
const SoftwarePageContent = [
    {
        'id': 'custom-software-development',
        'title': 'Custom Software Development',
        'text': 'At our organization, we specialize in custom software development, employing an innovative and client-centric approach to deliver tailored solutions. Our seasoned team of engineers and developers leverage advanced technologies and methodologies, including AI, machine learning, and agile principles, to develop custom software that addresses unique business needs. We focus on creating scalable, secure, and efficient software applications that drive digital transformation and ensure a competitive edge for enterprises across industries. Our commitment to quality, transparency, and ongoing support ensures a smooth and seamless journey from conceptualization to deployment and beyond, fostering digital success and growth for our clients.',
        'component': 'serviceBlock'
    },
    {
        'id': 'enterprise-application-solutions',
        'title': 'Enterprise Application Solutions',
        'text': 'Enterprise Application Solutions represents a cornerstone of modern business operations, providing integral platforms for enterprises to manage complex internal and customer-facing functions effectively. These scalable software solutions encompass a wide array of business operations including, but not limited to, supply chain management, customer relationship management, human resources, financial systems, and enterprise resource planning. Our goal is to streamline processes, reduce operational bottlenecks, increase productivity, and ultimately drive business growth. Leveraging the latest advancements in artificial intelligence, machine learning, cloud computing, and cybersecurity, we ensure that these solutions not only meet current business needs but also future-proof organizations for challenges on the horizon. With an unwavering commitment to business agility, innovation, and robust security, we strive to deliver enterprise application solutions that act as catalysts for transformative change.',
        'component': 'serviceBlock'
    },
    {
        'id': 'mobile-app-development',
        'title': 'Mobile App Development',
        'text': 'Mobile App Development is a critical element in today\'s digital landscape, forming a direct, accessible link between businesses and their customers. Our comprehensive approach encompasses the design, development, and deployment of high-performing, user-centric mobile applications compatible with both iOS and Android platforms. By blending innovative technologies with proven development methodologies, we ensure applications are robust, secure, and scalable, offering a seamless user experience. We prioritize functionality, usability, and aesthetics in equal measure, with a keen eye on emerging trends and user behavior patterns. The apps we create are not only intended to engage and retain users but also to act as strategic tools for business growth, customer engagement, and brand recognition. Our dedicated team of experienced developers, UI/UX designers, and project managers ensure that your mobile app development journey is structured, efficient, and goal-oriented.',
        'component': 'serviceBlock'
    },
    {
        'id': 'software-quality-assurance',
        'title': 'Software Quality Assurance',
        'text': 'Quality Assurance (QA) is an indispensable aspect of our development process, aimed at ensuring the reliability, efficiency, and high performance of software solutions. We adhere to stringent standards and follow rigorous testing methodologies to detect and resolve issues at an early stage. By incorporating SQA throughout the software lifecycle, we mitigate risks, avoid costly rework, and ensure that the final product aligns seamlessly with business objectives and user expectations. Our quality assurance team employs a variety of tests, including unit testing, integration testing, functional testing, and stress testing, among others, to verify system integrity. Additionally, we embrace the latest automation tools and techniques, facilitating efficient issue tracking and rapid corrective action. In essence, our commitment to SQA ensures that we deliver robust, secure, and flawless software that not only meets but surpasses customer requirements.',
        'component': 'serviceBlock'
    },
    {
        'id': 'maintenance-and-support-services',
        'title': 'L2 and L3 Support Services',
        'text': 'At our organization, we pride ourselves on offering superior L2 and L3 support services, designed to provide comprehensive technical assistance and efficient problem resolution to our customers. Our Level 2 support handles more complex issues that require in-depth technical knowledge, while our Level 3 support provides advanced expertise for the most critical and intricate problems. Leveraging a team of highly skilled professionals, we are committed to swiftly address any challenge, reducing downtime and ensuring your business processes remain undisturbed. Our goal is to facilitate seamless operation and high levels of satisfaction for all users, affirming our position as a reliable partner for all your technical support needs.',
        'component': 'serviceBlock'
    }
];
export default SoftwarePageContent;
