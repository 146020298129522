
const DevOpsPageContent = [
    {
        'id': 'infrastructure-automation',
        'title': 'Infrastructure Automation',
        'text': 'Transform your IT infrastructure with Sophotech. We specialize in providing automated solutions for the configuration, provisioning, deployment, and management of your IT infrastructure. Our DevOps and Infrastructure experts leverage the latest tools and technologies to automate your infrastructure, enabling you to respond to changing business needs faster and with greater agility. With our infrastructure automation services, you can reduce manual errors, improve system reliability, and optimize resource utilization, leading to better business outcomes. Contact us today to learn how we can help you achieve efficient IT management through infrastructure automation.',
        'component': 'serviceBlock'
    },
    {
        'id': 'continuous-integration-and-delivery',
        'title': 'Continuous Integration and Delivery',
        'text': 'At Sophotech, our team works in close partnership with you to design a customized CI/CD pipeline, utilizing industry-leading tools and methodologies for streamlined code integration, automated testing, and swift deployment. By choosing Sophotech for your CI/CD requirements, you can enjoy enhanced productivity, minimized risk, and the adaptability necessary to remain at the forefront of the constantly changing marketplace.',
        'component': 'serviceBlock'
    },
    {
        'id': 'cloud-infrastructure-management',
        'title': 'Cloud Infrastructure Management',
        'text': "Our company specializes in Cloud Infrastructure Management, which involves the effective management and maintenance of the hardware and software components of cloud computing environments. Our team of experts is skilled in managing the servers, storage devices, networking equipment, and virtualization software that comprise cloud infrastructures. We help organizations optimize their cloud resources, reduce costs, and dynamically provision and de-provision resources based on changing demands. Also ensure that cloud environments are secure, compliant, and meet the performance requirements of organizations. By partnering with Sophotech, you'll gain access to best-in-class methodologies and technologies, ensuring your organization stays agile, secure, and ahead of the curve in today's competitive landscape. Let us help you maximize the value of your cloud investments while minimizing complexity and risk.",
        'component': 'serviceBlock'
    },
    {
        'id': 'infrastructure-monitoring',
        'title': 'Infrastructure Monitoring',
        'text': 'Sophotech can help to provide your organization with outstanding visibility and proactive control over your cloud environment. With us, you can optimize resource utilization, minimize downtime, and meet your SLAs and regulatory requirements.',
        'component': 'serviceBlock'
    },
    {
        'id': 'security-and-compliance',
        'title': 'Security and Compliance',
        'text': 'Sophotech as a highly qualified team understands the value of keeping any data safe. Therefore, we can guarantee that our methods can provide you and also your clients with protection and also security. Our IT infrastructure defense practices include proactive day-to-day monitoring, meaning you can rest assured that your information is safe.',
        'component': 'serviceBlock'
    },
    {
        'id': 'devops-consulting-and-training',
        'title': 'DevOps Consulting and Training',
        'text': "We realized that in today's world, speed and quality are constantly evolving, so our team decided to make life easier for our customers. Our DevOps consultants help different clients realize and improve various DevOps practices and methodologies. We know that every company has its own needs and characteristics, so our services are always aimed at meeting the individual needs of the client. We help our clients build a culture of continuous improvement that allows for higher results that affect product quality and speed of response to changing market demands. In addition, our professionals will help in the provision of automated DevOps tools that greatly facilitate the work of the team.",
        'component': 'serviceBlock'
    }
];
export default DevOpsPageContent;
