import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-07901X07QY"
        ></script>
        <script type="text/javascript">{`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-07901X07QY');
                `}</script>
        <script type="text/javascript">{`
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "je8ysmi2aq");
                `}</script>
        <meta charSet="utf-8" />
        <title>{pageTitle} - Software Development Services</title>
        <meta
          name="description"
          content="Sophotech - Software Development Services"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
    </>
  );
};

PageHelmet.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default PageHelmet;
