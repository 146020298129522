import React from "react";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import ArchitectureDetailsArea from "./ServicesDetailsArea/ArchitectureDetailsArea";
import ServicesDetailsHeader from "./ServicesDetailsHeader/ServicesDetailsHeader";

const SolArchitectureDetails = () => {
  return (
    <>
      <PageHelmet pageTitle="Cloud Solutions Architecture Services" />
      <HomeOneHeader />
      <ServicesDetailsHeader />
      <ArchitectureDetailsArea />
      <Footer />
    </>
  );
};

export default SolArchitectureDetails;
