import React from "react";
import { Link } from "react-router-dom";
import solutionsArchitectureDetails from "../../../data/solutionsArchitectureDetails";
import ServiceBlock from "../../../components/ServiceBlock/ServiceBlock";
import CustomServiceBlock from "../../../components/ServiceBlock/CustomServiceBlock/CustomServiceBlock";
import urlsData from "../../../data/urlsData";

const scrollToBlock = (event) => {
  const block_id = event.currentTarget.id.replace("-link", "");
  const anchor = document.querySelector(`#${block_id}`);
  anchor.scrollIntoView({ behavior: "smooth", block: "center" });
};

const ArchitectureDetailsArea = () => {
  const getContentBlock = (elementId) => {
    return solutionsArchitectureDetails.filter(
      (item) => item.id === elementId
    )[0];
  };

  return (
    <>
      <section className="services__details pt-115 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 order-last order-lg-first">
              <div className="services__sidebar mr-50">
                <div className="services__widget grey-bg-18 mb-40">
                  <div className="services__widget-title">
                    <h4>Categories</h4>
                  </div>
                  <div className="services__widget-content">
                    <div className="services__link">
                      <ul>
                        {solutionsArchitectureDetails.map((contentBlock, i) => {
                          return (
                            <li key={i}>
                              <Link
                                to={`${urlsData.service_1}#${contentBlock.id}`}
                                onClick={scrollToBlock}
                                id={`${contentBlock.id}-link`}
                              >
                                {contentBlock.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services__text">
                <h1>
                  What services do we offer?
                </h1>
                <br />
                <ServiceBlock
                  elementId={getContentBlock("architecture-consulting").id}
                  title={getContentBlock("architecture-consulting").title}
                  text={getContentBlock("architecture-consulting").text}
                />
                <ServiceBlock
                  elementId={getContentBlock("architecture-assessment").id}
                  title={getContentBlock("architecture-assessment").title}
                  text={getContentBlock("architecture-assessment").text}
                />
                <ServiceBlock
                  elementId={getContentBlock("architecture-migration").id}
                  title={getContentBlock("architecture-migration").title}
                  text={getContentBlock("architecture-migration").text}
                />
                <ServiceBlock
                  elementId={getContentBlock("architecture-design").id}
                  title={getContentBlock("architecture-design").title}
                  text={getContentBlock("architecture-design").text}
                />
              </div>
              <div className="services__img mb-45 w-img">
                <img src="assets/img/services/details/services-01.jpg" alt="" />
              </div>
              <div className="services__text">
                <h1>
                  Why choose us? We provide consulting in the following areas:
                </h1>
                <br />
                <ServiceBlock
                  elementId={getContentBlock("designing-software-systems").id}
                  title={getContentBlock("designing-software-systems").title}
                  text={getContentBlock("designing-software-systems").text}
                />
                <CustomServiceBlock
                  elementId={
                    getContentBlock("improving-existing-software-systems").id
                  }
                  title={
                    getContentBlock("improving-existing-software-systems").title
                  }
                  text={
                    getContentBlock("improving-existing-software-systems").text
                  }
                />
                <ServiceBlock
                  elementId={getContentBlock("evaluating-software-options").id}
                  title={getContentBlock("evaluating-software-options").title}
                  text={getContentBlock("evaluating-software-options").text}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArchitectureDetailsArea;
