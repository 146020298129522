import React from "react";
import HomeSingleFeature from "../../../components/HomeSingleFeature/HomeSingleFeature";
import { FiBook, FiLock } from "react-icons/fi";
import { FaLightbulb } from "react-icons/fa";
import { BsHouseDoor, BsPeopleFill } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";

const HomeFeatures = () => {
  return (
    <>
      <section
        className="features__area pb-100 mt--100 wow fadeInUp"
        data-wow-delay=".5s"
      >
        <div className="container">
          <div className="features__inner fix">
            <div className="row g-0 ">
              <HomeSingleFeature
                icon={<FiBook />}
                title="Infrastructure as Code"
              />
              <HomeSingleFeature
                icon={<FaLightbulb />}
                title="Solution Design and Architecture"
              />
              <HomeSingleFeature
                icon={<BsHouseDoor />}
                title="Cloud Training for In-House Teams"
              />
              <HomeSingleFeature
                icon={<IoDocumentTextOutline />}
                title="Compliance Services (ISO, GDPR, HIPAA, FDA, CFPB, SEC)"
              />
              <HomeSingleFeature
                icon={<FiLock />}
                title="Security Audit and Penetration Testing"
              />
              <HomeSingleFeature
                icon={<BsPeopleFill />}
                title="Dedicated Senior Teams"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFeatures;
