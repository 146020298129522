import { FaEnvelope, FaLinkedin } from "react-icons/fa";

import React from "react";
import urlsData from "../../data/urlsData";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer__area grey-bg pt-100">
          <div className="footer__top pb-45">
            <div className="container">
              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Company</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <a href={urlsData.about}>About Us</a>
                          </li>
                          <li>
                            <a href={urlsData.service_1}>Consulting</a>
                          </li>
                          <li>
                            <a href={urlsData.contact}>Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-4 col-sm-6">
                  <div
                    className="footer__widget mb-45 wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <div className="footer__widget-title">
                      <h4>Resources</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <a href="#">Learning Center</a>
                          </li>
                          <li>
                            <a href="#">Support</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-4 col-sm-6">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Use Cases</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <a href="#">Enterprise</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 col-sm-6">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Information</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__info">
                        <ul>
                          <li>
                            <div className="icon theme-color ">
                              <i>
                                <FaEnvelope />
                              </i>
                            </div>
                            <div className="text theme-color ">
                              <span>
                                <a href="mailto:contact@sopho.tech">
                                  contact@sopho.tech
                                </a>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__copyright">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer__copyright-text">
                    <p>Copyright © 2024 All Rights Reserved</p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer__social theme-social f-right">
                    <ul>
                      <li>
                        <a href="https://www.linkedin.com/company/sophotech/">
                          <i>
                            <FaLinkedin />
                          </i>
                          <i>
                            <FaLinkedin />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
