import React from "react";
import { Link } from "react-router-dom";
import devopsPageContent from "../../../data/devopsPageContent";
import ServiceBlock from "../../../components/ServiceBlock/ServiceBlock";
import urlsData from "../../../data/urlsData";

const scrollToBlock = (event) => {
  const block_id = event.currentTarget.id.replace("-link", "");
  const anchor = document.querySelector(`#${block_id}`);
  anchor.scrollIntoView({ behavior: "smooth", block: "center" });
};

const DevOpsDetailsArea = () => {

  return (
    <>
      <section className="services__details pt-115 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 order-last order-lg-first">
              <div className="services__sidebar mr-50">
                <div className="services__widget grey-bg-18 mb-40">
                  <div className="services__widget-title">
                    <h4>Categories</h4>
                  </div>
                  <div className="services__widget-content">
                    <div className="services__link">
                      <ul>
                        {devopsPageContent.map((contentBlock, i) => {
                          return (
                            <li key={i}>
                              <Link
                                to={`${urlsData.service_1}#${contentBlock.id}`}
                                onClick={scrollToBlock}
                                id={`${contentBlock.id}-link`}
                              >
                                {contentBlock.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services__text">
                <h1>
                  What services do we offer?
                </h1>
                <br />
                { devopsPageContent.map((contentBlock, i) => {
                    return (
                     <ServiceBlock
                         key={i}
                         elementId={contentBlock.id}
                         text={contentBlock.text}
                         title={contentBlock.title}
                     />
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DevOpsDetailsArea;
