import React from "react";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import ServicesDetailsHeader from "./ServicesDetailsHeader/ServicesDetailsHeader";
import SoftwareDetailsArea from "./ServicesDetailsArea/SoftwareDetailsArea";

const SoftwareDetails = () => {
  return (
    <>
      <PageHelmet pageTitle="DevOps and Infrastructure Services" />
      <HomeOneHeader />
      <ServicesDetailsHeader />
      <SoftwareDetailsArea />
      <Footer />
    </>
  );
};

export default SoftwareDetails;
