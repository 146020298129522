import Collapsible from "react-collapsible";
import { FaEnvelope } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import urlsData from "../../data/urlsData";

const Sidebar = ({ show, handleClose }) => {
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            <div className="logo">
              <a href="index.html">
                <img src="assets/img/logo/logo.png" alt="logo" />
              </a>
            </div>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <section>
              <div className="p-0">
                <div className="sidebar__content">
                  <div className="tab-content" id="sidebar-tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="menu"
                      role="tabpanel"
                      aria-labelledby="menu-tab"
                    >
                      <div className="side_navBar">
                        <div className="about iconAdd">
                          <NavLink to={urlsData.home}>Home</NavLink>
                        </div>
                        <div className="about iconAdd">
                          <NavLink to={urlsData.about}>About Us</NavLink>
                        </div>
                        <Collapsible
                          trigger={
                            <NavLink to={urlsData.services}>Services</NavLink>
                          }
                          triggerTagName="div"
                          triggerOpenedClassName="icon_close"
                          triggerClassName="iconAdd"
                          open={false}
                        >
                          <ul className="sidebar_sub_menu submenu text-white">
                            <li>
                              <NavLink to={urlsData.service_1}>
                                Services Details
                              </NavLink>
                            </li>
                          </ul>
                        </Collapsible>
                        <div className="about iconAdd border-0">
                          <NavLink to={urlsData.contact}>Contact Us </NavLink>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="info"
                      role="tabpanel"
                      aria-labelledby="info-tab"
                    >
                      <div className="sidebar__info">
                        <div className="logo mb-40">
                          <a href="index.html">
                            <img src="assets/img/logo/logo.png" alt="logo" />
                          </a>
                        </div>
                        <p>Please feel free to reach out</p>
                        <a href="contact.html" className="z-btn z-btn-white">
                          contact us
                        </a>
                        <div className="sidebar__contact mt-30">
                          <ul>
                            <li>
                              <div className="icon">
                                <i>
                                  <FaEnvelope />
                                </i>
                              </div>
                              <div className="text ">
                                <span>
                                  <a href="mailto:contact@sopho.tech">
                                    contact@sohpo.tech
                                  </a>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Sidebar;
