import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SingleService = ({ icon, title, body, url }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <div className="services__item mb-90">
          <div className="services__icon mb-35">
            <img
              src={`assets/img/icon/services/services-${icon}.png`}
              alt="services"
            />
          </div>
          <div className="services__content">
            <h3>
              <Link to={url}>{title}</Link>
            </h3>
            <p>{body}</p>
          </div>
        </div>
      </div>
    </>
  );
};

SingleService.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SingleService;
