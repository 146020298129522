import React from "react";
import SingleService from "../../../components/SingleService/SingleService";
import urlsData from "../../../data/urlsData";

const HomeServices = () => {
  return (
    <>
      <section className="services__area pt-115 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div
                className="section__title section__title-3 text-center mb-90 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span>Our Services</span>
                <h2>
                  Our expertise is in developing cloud-native solutions that
                  emphasize automation and reproducibility.
                </h2>
              </div>
            </div>
          </div>
          <div className="row" data-testid="services">
            <SingleService
              icon="1"
              title="Solutions Architecture"
              body="Transparent and reliable deployments on any scale."
              url={urlsData.service_1}
            />
            <SingleService
              icon="2"
              title="Software Development"
              body="Custom-tailored solutions to meet specific business needs for various use cases."
              url={urlsData.service_3}
            />
            <SingleService
              icon="6"
              title="Productionisation"
              body="Transform an existing prototype into a production-ready package optimized for a faster and more efficient product launch."
              url={urlsData.service_4}
            />
            <SingleService
              icon="3"
              title="DevOps and Infrastructure"
              body="Move workloads to the cloud with Terraform and Kubernetes."
              url={urlsData.service_2}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
